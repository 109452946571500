<template>
  <a-card>
    <a-page-header
      style="border: 1px solid rgb(235, 237, 240); margin-bottom: 35px"
      title="订单关联商品列表"
      :sub-title="this.queryParam.order_no"
      @back="() => $router.go(-1)"
    ></a-page-header>
    <s-table
      ref="table"
      size="small"
      rowKey="id"
      :loading="loading"
      :columns="columns"
      :data="loadData"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <div slot="money_render" slot-scope="text" style="text-align: right; min-width: 100px; max-width: 140px">
        {{ (text / 100).toFixed(2) | numberFormat }}
      </div>
      <div slot="count_render" slot-scope="text" style="text-align: right; min-width: 90px; max-width: 160px">
        {{ text }}
      </div>
    </s-table>
  </a-card>
</template>

<script>
import { STable } from '@/components'
import { Base64 } from 'js-base64'
import { wechat_order_detail_list } from '@/api/c_wms_wechat_counter_order'

export default {
  name: 'TableList',
  components: {
    STable
  },
  data () {
    return {
      loading: false,
      confirmLoading: false,
      // 创建窗口控制
      visible: false,
      select_status_visible: false,
      select_edit_record: {},
      select_status_data: '',
      mdl: {},
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '序号',
          width: 50,
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '商品名称',
          ellipsis: true,
          width: 150,
          dataIndex: 'name'
        },
        {
          title: '商品条形码',
          ellipsis: true,
          width: 150,
          dataIndex: 'barcode'
        },
        {
          title: '商品原价',
          width: 150,
          dataIndex: 'original_price',
          scopedSlots: { customRender: 'money_render' }
        },
        {
          title: '商品实价',
          width: 150,
          dataIndex: 'price',
          scopedSlots: { customRender: 'money_render' }
        },
        {
          title: '购买数量',
          width: 150,
          dataIndex: 'count',
          scopedSlots: { customRender: 'count_render' }
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        return wechat_order_detail_list(Object.assign(parameter, this.queryParam), this.mdl.order_no)
          .then(({ data }) => {
            return data
          })
      }
    }
  },
  created () {
    this.mdl = JSON.parse(Base64.decode(this.$route.query.record || ''))
    console.log('========', this.mdl.order_no)
    if (this.mdl) {
      this.queryParam.order_no = this.mdl.order_no
    }
  }
}
</script>
